import packageInfo from '../../package.json';
import { IEnvironment } from './environment-model';

export const environment: IEnvironment = {
    isProduction: true,
    name: 'production',
    apiConfig: {
        baseUrl: 'https://ak-let-api-gateway-production.azure-api.net/lettingapp',
        version: 2,
    },
    auth: {
        authorizationParams: {
            audience: 'https://letting-api.akelius.com/',
            scope: 'openid profile email',
            redirect_uri: window.location.origin,
        },
        cacheLocation: 'localstorage',
        clientId: 'oump4b6X9nisO9pR9GUkEoO7nrpzhzSv',
        connection: 'auth0-aad-production',
        domain: 'akelius.eu.auth0.com',
        httpInterceptor: {
            allowedList: [{ uri: 'https://ak-let-api-gateway-production.azure-api.net/*' }],
        },
    },
    intranetImageUploadUrl: 'https://intranet.akelius.com/upload/pictures/marketing',
    intranetViewingUnitUrl: 'https://intranet.akelius.com/permalink/property-asset',
    googleAnaliticsKey: 'G-6574R0EWXN',
    sentryConfiguration: {
        enabled: true,
        debug: false,
        environment: 'production',
        release: 'lettingapp-' + packageInfo.version,
        ignoreErrors: [
            /Loading chunk (.*) failed/,
            /403/,
            /0 Unknown Error/,
            /Non-Error exception captured/,
            /504 Gateway Timeout/,
        ],
        dsn: 'https://791014abdf8b40b097ea25081f9c0d4b@o143872.ingest.sentry.io/5727564',
    },
    documentServiceTeaserImageUrl:
        'https://ak-let-api-gateway-production.azure-api.net/api/media/property-main-pictures/{costCenterCode}/download/400',
    acmUrl: 'https://construction.akelius.{topLevelDomain}',
};
